/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2019-10-29
 */

import axios from "axios"
import { notification } from "antd"
import helper from "../utils/helper"
import config from "config"

let baseURL = config.apiHost
console.log("baseURL===", config)

const proxyURL = `${config.apiHost}/admin/proxy/internal?uri=`
if (process.env.NODE_ENV === "develop") {
    // baseURL = 'http://dev-api.momo.im/admin/proxy/internal?uri='
    // baseURL = 'http://dev-api.momo.im/admin/proxy/training?uri='
    // console.log('base Url');
    // baseUrl = config.apiHost
}

export const request = axios.create({
    timeout: 500000,
})
// Add a request interceptor
request.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        const accessToken = localStorage.getItem("access_token")
        config.headers["Authorization"] = `Bearer ${accessToken}`
        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
)
// Add a response interceptor
request.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (response.status === 200) {
            if (response.data) {
                return response.data
            } else {
                alert("server error")
            }
        } else {
            return response
        }
    },
    function (error) {
        // console.log('error', arguments)
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const res = error.response
        if (res && res.data && res.data.meta) {
            if (res.data.meta.code === 401 || res.data.meta.code === 101054) {
                // 未登录
                window.localStorage.removeItem("access_token")
                window.location.href = "/sign-in"
            } else {
                notification.error({
                    key: "ajaxError",
                    message: "Error",
                    description: res.data.meta.message || error.message || "network error connection refused",
                })
            }
            return res.data
            // alert(res.data.meta.message)
        } else {
            notification.error({
                key: "ajaxError",
                message: "Error",
                duration: null,
                description: error.message || "network error connection refused",
            })
            return Promise.reject(error)
        }
    }
)
export const formatUrl = (url, query, subject = true, block = "") => {
    if (subject) {
        url = `/subjects/${localStorage.getItem("subject_id")}${url}`
    }
    if (query) {
        url = helper.format(url, query)
    }
    if (!url.startsWith("http")) {
        if (block === "training") {
            const _proxyURL = `${config.apiHost}/admin/proxy/training?uri=`
            url = `${_proxyURL}${encodeURIComponent(url)}`
        } else if (url.startsWith("/admin") || url.startsWith("/api") || url.startsWith("/rpc")) {
            url = `${baseURL}${encodeURIComponent(url)}`
        } else {
            url = `${proxyURL}${encodeURIComponent(url)}`
        }
    }
    return url
}

export default {
    // 登录
    signIn: async (data = {}) => {
        return request.post(formatUrl("/admin/login/token", null, false), { ...data })
    },
    // 退出
    signOut: async () => {
        return request.delete(formatUrl("/admin/login/token", null, false), {})
    },
    // 字典单词列表
    words: async (params = {}) => {
        return request(formatUrl("/words"), { params })
    },
}
