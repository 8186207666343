import { formatUrl, request } from "./index"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getPromptTemplates: async (params) => {
        return request(formatUrl(`/prompt_templates`), { params })
    },
    getPromptTemplate: async (id) => {
        return request(formatUrl(`/prompt_templates/${id}`), {})
    },
    postPromptTemplates: async (data) => {
        return request.post(formatUrl(`/prompt_templates`), data)
    },
    putPromptTemplates: async (_id, data) => {
        return request.put(formatUrl(`/prompt_templates/${_id}`), data)
    },
    deletePromptTemplates: async (_id) => {
        return request.delete(formatUrl(`/prompt_templates/${_id}`), { data: {} })
    },

    getSubPromptTemplates: async (pid, params) => {
        return request(formatUrl(`/prompt_templates/${pid}`), { params })
    },
    postSubPromptTemplates: async (pid, data) => {
        return request.post(formatUrl(`/prompt_templates/${pid}`), data)
    },
    putSubPromptTemplates: async (pid, _id, data) => {
        return request.put(formatUrl(`/prompt_templates/${pid}/sub_prompt_templates/${_id}`), data)
    },
    deleteSubPromptTemplates: async (pid, _id) => {
        return request.delete(formatUrl(`/prompt_templates/${pid}/sub_prompt_templates/${_id}`), { data: {} })
    },
}
